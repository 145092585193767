var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v(" What are you doing? (checkboxes) "),
    ]),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-lg-10" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-4" },
            [
              _c("icon-checkbox", {
                attrs: { icon: "nc-icon nc-ruler-pencil", title: "Design" },
                model: {
                  value: _vm.model.design,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "design", $$v)
                  },
                  expression: "model.design",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-4" },
            [
              _c("icon-checkbox", {
                attrs: { icon: "nc-icon nc-laptop", title: "Code" },
                model: {
                  value: _vm.model.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "code", $$v)
                  },
                  expression: "model.code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-4" },
            [
              _c("icon-checkbox", {
                attrs: { icon: "nc-icon nc-atom", title: "Develop" },
                model: {
                  value: _vm.model.develop,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "develop", $$v)
                  },
                  expression: "model.develop",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }