var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h5", { staticClass: "info-text" }, [
      _vm._v(" Let's start with the basic information (with validation)"),
    ]),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _c(
          "div",
          { staticClass: "picture-container" },
          [
            _c(
              "el-upload",
              {
                staticClass: "picture",
                attrs: {
                  action: "https://jsonplaceholder.typicode.com/posts/",
                  "on-change": _vm.handlePreview,
                  "auto-upload": false,
                  "show-file-list": false,
                },
              },
              [
                _vm.model.imageUrl
                  ? _c("img", {
                      staticClass: "picture-src",
                      attrs: { src: _vm.model.imageUrl },
                    })
                  : _vm._e(),
              ]
            ),
            _c("h6", { staticClass: "description" }, [
              _vm._v("Choose Picture"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.firstName,
                expression: "modelValidations.firstName",
              },
            ],
            attrs: {
              name: "firstName",
              placeholder: "First Name (required)",
              error: _vm.getError("firstName"),
              "addon-left-icon": "nc-icon nc-single-02",
            },
            model: {
              value: _vm.model.firstName,
              callback: function ($$v) {
                _vm.$set(_vm.model, "firstName", $$v)
              },
              expression: "model.firstName",
            },
          }),
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.lastName,
                expression: "modelValidations.lastName",
              },
            ],
            attrs: {
              name: "lastName",
              placeholder: "Last Name (required)",
              error: _vm.getError("lastName"),
              "addon-left-icon": "nc-icon nc-circle-10",
            },
            model: {
              value: _vm.model.lastName,
              callback: function ($$v) {
                _vm.$set(_vm.model, "lastName", $$v)
              },
              expression: "model.lastName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-sm-10 mt-3" },
        [
          _c("fg-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.email,
                expression: "modelValidations.email",
              },
            ],
            attrs: {
              name: "email",
              placeholder: "Email (required)",
              error: _vm.getError("email"),
              "addon-left-icon": "nc-icon nc-send",
            },
            model: {
              value: _vm.model.email,
              callback: function ($$v) {
                _vm.$set(_vm.model, "email", $$v)
              },
              expression: "model.email",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }