var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row d-flex justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-md-10 mr-auto ml-auto" },
        [
          _c(
            "wizard",
            [
              _c("template", { slot: "header" }, [
                _c("h3", { staticClass: "card-title" }, [
                  _vm._v("Build your profile"),
                ]),
                _c("h3", { staticClass: "description" }, [
                  _vm._v("This information will let us know more about you."),
                ]),
              ]),
              _c(
                "wizard-tab",
                { attrs: { "before-change": () => _vm.validateStep("step1") } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "nc-icon nc-single-02" }),
                    _vm._v("\n              About\n            "),
                  ]),
                  _c("first-step", {
                    ref: "step1",
                    on: { "on-validated": _vm.onStepValidated },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                { attrs: { "before-change": () => _vm.validateStep("step2") } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "nc-icon nc-touch-id" }),
                    _vm._v("\n              Account\n            "),
                  ]),
                  _c("second-step", {
                    ref: "step2",
                    on: { "on-validated": _vm.onStepValidated },
                  }),
                ],
                2
              ),
              _c(
                "wizard-tab",
                { attrs: { "before-change": () => _vm.validateStep("step3") } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "nc-icon nc-pin-3" }),
                    _vm._v("\n              Address\n            "),
                  ]),
                  _c("third-step", { ref: "step3" }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }